import React from "react"
import { Container } from "@material-ui/core"
import Layout from "../../components/Layout/layout"
import PageInfoSection from "../../components/PageInfoSection/PageInfoSection"
import ImageCard from '../../components/PageInfoSection/components/ImageCard'
import image from '../../assets/images/redesign-rebuild.jpeg'
import Squares from '../../components/Squares/Squares'
import { graphql, useStaticQuery } from 'gatsby'

const RedesignRebuildPage = () => {
  const { redesign } = useStaticQuery(graphql`
  query redesign {
    redesign: file(relativePath: {eq: "redesign-rebuild.jpeg"}) {
      childImageSharp {
        fluid {
          src
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `)
  console.log(redesign)
  return (
    < Layout >
      <Squares>
        <Container>
          <PageInfoSection
            title={`redesign &`}
            blueTitle="rebuild"
            text="Industry leaders know the importance of constantly improving user experiences. Our team will revitalize your website into a fresh, unique, and convenient platform for your clientele."
          >
            <ImageCard image={redesign.childImageSharp.fluid} text="Redesign and Rebuild" />
          </PageInfoSection>
        </Container>
      </Squares>
    </Layout >
  )
}

export default RedesignRebuildPage
